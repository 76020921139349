import { StationApi } from '@/api/attendance/station/StationApi';
import { rejects } from 'assert';

export class StationService {
    private api = new StationApi();
    public getMyPlaceList(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getPlaceList(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    public getPlaceList(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getStaffPlaceList(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    getUserList(searchParams: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getUserList(searchParams).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    removeUser(selectIds: String[]): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.removeUser(selectIds).then((res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    //发起驻场申请
    public submit(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.submit(params).then((res: any) => {
                resolve(res)
            }).catch((err: any) => {
                reject(err)
            })
        })
    }
    //获取详情 
    public getInfo(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getInfo(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
}