import AttendanceBaseService from '../base/AttendanceBaseService';

export class StationApi extends AttendanceBaseService {

    constructor() {
        super("stationing");
    }

    /**
  * 获取我的可选驻场列表
  */
    public getPlaceList(params: any): Promise<any> {
        return this.request('place/avaliable/list', this.rest.METHOD_GET, params);
    }

    /**
   * 获取驻场员工所在驻场地列表
   */
    public getStaffPlaceList(params: any): Promise<any> {
        return this.request('staff/place/list', this.rest.METHOD_GET, params);
    }

    /**
     * 获取驻场地员工列表
     * @param searchParams 
     */
    getUserList(searchParams: any): Promise<any> {
        return this.request('list', this.rest.METHOD_GET, searchParams);
    }

    removeUser(data: String[]) {
        return this.request('remove', this.rest.METHOD_POST, data);
    }
    /**
   * 发起驻场申请
   */
    public submit(params: any): Promise<any> {
        return this.request('apply', this.rest.METHOD_POST, params);
    }
    //获取详细 
    public getInfo(params: any): Promise<any> {
        return this.request('detail', this.rest.METHOD_GET, params);
    }
}