
import { defineComponent } from "vue";
import Header from "@/components/Header.vue";
import NoDate from "@/components/NoData.vue";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import { StationService } from "@/services/attendance/station/StationService";
import { useRouter } from "vue-router";
import { menuController } from "@ionic/vue";
import PlaceSearchPanel from "./PlaceSearchPanel.vue";
export default defineComponent({
  name: "AttPlaceList",
  components: {
    Header,
    NoDate,
    CardLoading,
    PlaceSearchPanel,
  },
  data() {
    const btnIconRight = ["iconshaixuanxuanzhong"];
    const btnSizeRight = ["34px"];
    return {
      list: [],
      islist: true,
      service: new StationService(),
      router: useRouter(),
      btnIconRight: btnIconRight,
      btnSizeRight: btnSizeRight,
      hasMore: true, //是否有更多数据
      showNoDate: true, //展示暂无数据
      searchParams: {
        size: 10,
        page: 1,
        keyword: "",
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    menuSearchEvent(data: any) {
      Object.assign(this.searchParams, data);
      this.init();
    },
    //重新从第一页获取
    init() {
      this.searchParams.page = 1;
      this.handelGetList((res: any) => {
        this.list = res;
        this.showNoDate = res == null || res.length <= 0;
      });
    },
    //刷新
    doRefresh(event: any) {
      this.init();
      setTimeout(() => {
        event.target.complete();
      }, 1000);
    },
    //下一页
    nextPage(event: any) {
      if (!this.hasMore) {
        event.target.complete();
        return;
      }
      this.searchParams.page++;
      this.handelGetList((result: any) => {
        if (result) {
          this.list = this.list.concat(result);
        }
        event.target.complete();
      });
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    //请求数据
    handelGetList(callback: any) {
      this.service
        .getPlaceList(this.searchParams)
        .then((res: any) => {
          this.islist = false;
          if (res.length < this.searchParams.size) {
            this.hasMore = false;
          }
          callback(res);
        })
        .catch(() => {
          this.islist = false;
        });
    },
    goInto(id: string) {
      this.router.push({
        path: "/att/place/user",
        query: {
          placeId: id,
        },
      });
    },
    onFiltClick() {
      menuController.open("PlaceMenu");
    },
  },
});
