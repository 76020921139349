
import { menuController, modalController, popoverController } from "@ionic/vue";
import { defineComponent } from "vue";
//组件
import SelectExecutor from "@/components/selectexecutor/SelectExecutor.vue";
import SelectOrganization from "@/views/OKR/modals/SelectOrganization.vue";
import AvatarList from "@/components/AvatarList.vue"; 
import Utils from "@/utils/Utils";
import BaseService from "@/services/base/BaseService";

export default defineComponent({
  name: "PlaceSearchPanel",
  components: {
    AvatarList,
  },
  props: {
    selectCondition: Object,
    conditionId: String,
  },
  emits: ["on-close", "on-init","on-reset"],
  data() {
    return {
      userArr: new Array<any>(), //驻场人员Id
      joinArr: new Array<any>(), //所属组织
      //----筛选条件字段--------
      searchPanelForm: {
        keyword: "", //搜索名称
        userId: "", //驻场人员Id
        orgId:""
      },
      baseService: new BaseService(),
    };
  },
  methods: {
    subStringUserName(name: string) {
      return Utils.subStringUserName(name, 2);
    },

    //关闭
    onClose() {
      menuController.close();
    },
    keyNameHandler(ev: any) {
      const keyCode = window.event ? ev.keyCode : ev.which;
      if (keyCode === 13) {
       this.confirm();
      }
    },
    //驻场人员Id
    async selectCreator() {
      const modal = await modalController.create({
        component: SelectExecutor,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            type: "other",
            executorList: this.userArr,
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        this.userArr = data.data;
      }
    },
    //删除所选的人
    deleteUser(type: any, item: any, index: number) {
       if (type == "Creator") {
        //驻场人员Id
        this.userArr.splice(index, 1);
      }else if(type=="Joiner"){
        this.joinArr.splice(index, 1);
      }
    },
    //所属组织
    async selectOrg(){
      let objParam:any=[];
      let getQueryObj={};
      if(this.joinArr.length>0){
        let obj:any=this.joinArr[0];
        getQueryObj = {
              checkedId: obj.id,
              checkedName: obj.name,
              checkedType: obj.type,
            };
      }
      const modal = await modalController.create({
        component: SelectOrganization,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
            type: "other",
            queryObj: getQueryObj,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        let objs:any=[];
        objs.push({id:data.checkedObj.checkedId,name:data.checkedObj.checkedName,type:data.checkedObj.checkedType});
        this.joinArr = objs;
      }
    },
    //确定检索
    confirm() {      
      this.searchPanelForm.keyword = this.baseService.trim(this.searchPanelForm.keyword);
      if (Utils.isInCludeEmoji(this.searchPanelForm.keyword)) {
        Utils.presentToastWarning("不可以包含表情");
        return;
      }
      //组织
      if(this.joinArr.length>0){
          this.searchPanelForm.orgId=this.joinArr[0].id;
      }
      if(this.userArr.length>0){
          this.searchPanelForm.userId=this.userArr[0].userId;
      }

      this.$emit("on-close", this.searchPanelForm);
      this.onClose();
    },
    //重置检索条件
    reset() {
      this.searchPanelForm = {
        orgId:"",
        keyword: "", //搜索名称
        userId: ""
      };
      this.userArr = []; //驻场人员Id
      this.joinArr=[];

      this.$emit("on-close", this.searchPanelForm);
      this.onClose();
    },
    avatarData(item: any) {
      var users: any = [];
      let obj = { name: item.name, img: item.photo, id: item.userId };
      users.push(obj);
      return users;
    },
  },
});
